export const environment = {
    production: true,
    CONSTANTS: {
        DART_SERVICE: 'https://hd4siqrm8a.execute-api.us-east-1.amazonaws.com/staging',
    },
    AWS: {
        REGION: 'us-east-1',
        USER_POOL_ID: 'us-east-1_D2VbU34Pw',
        USER_POOL_WEB_CLIENT_ID: '7uq449cqbr65f17eh2mafos278',
        USER_IDENTITY_POOL_ID: 'us-east-1:f401987c-9098-40d5-b1a1-a8896f864141',
        OAUTH: {
            DOMAIN: 'dart-bp-internal-staging.auth.us-east-1.amazoncognito.com',
            REDIRECT_SIGN_IN: 'https://staging.gauth.billpocket.com',
            REDIRECT_SIGN_OUT: 'https://staging.gauth.billpocket.com',
        }
    },
    COOKIE_STORAGE: {
        DOMAIN: '.billpocket.com',
        PATH: '/',
        EXPIRES: 365,
        SECURE: false,
    }
};
